import React from "react"
import { graphql, PageProps } from "gatsby"
import Helmet from "react-helmet"

import SEO from "../../components/SEO"
import { Vimeo } from "../../components/Video"

interface DataType {
  flick: Contentful.Flick
}

function WatchTemplate({ data }: PageProps<DataType>) {
  const { metadata, vimeoId } = data.flick

  return (
    <React.Fragment>
      <Helmet>
        <body className="watch-page" />
      </Helmet>
      <SEO {...metadata} />
      {vimeoId && <Vimeo id={vimeoId} />}
    </React.Fragment>
  )
}

export default WatchTemplate

export const pageQuery = graphql`
  query ($slug: String!, $locale: String!) {
    flick: contentfulFlick(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      vimeoId
      allowPublic
      metadata {
        title
        description
        keywords
        image {
          url
        }
      }
      slug
    }
  }
`
