import React from "react"
// @ts-ignore
import * as styles from "./Video.module.scss"

interface PlayerProps {
  src: string
}

function Player({ src }: PlayerProps) {
  return (
    <div className={styles.responsivePlayer}>
      <iframe title={src} src={src} width="640" height="360" frameBorder="0" allowFullScreen />
    </div>
  )
}

interface YoutubeProps {
  id: string
}

export function YouTube({ id }: YoutubeProps) {
  return <Player src={`https://www.youtube-nocookie.com/embed/${id}?rel=0`} />
}

interface VimeoProps {
  id: string
}

export function Vimeo({ id }: VimeoProps) {
  return <Player src={`https://player.vimeo.com/video/${id}`} />
}
